import Header from "@/components/Header.vue";
//import userAPI from "@/api/userAPI.js";
import adminAPI from "@/api/adminAPI.js";
import reportsAPI from "@/api/reportsAPI.js";
import notifications from "@/components/common/notifications";
import store from "@/store/index";
import permissions from "@/api/permissions.js";

export default {
  components: {
    Header,
    notifications,
  },
  data: () => ({
    errorType: "",
    message: "",
    loading: false,
    allowDownload: false,
    formData: {},
    batch_id: null,
    exportLoading: false,

    //---------------------------------------------------
    //Used for permission handling
    //---------------------------------------------------
    permissions: {},
    //---------------------------------------------------
  }),
  mounted() {},

  methods: {
    uploadData() {
      //e.preventDefault();
      let isValid = true;
      this.loading = true;
      this.vrNotify.visible = false;
      this.allowDownload = false;
      this.batch_id = null;

      //Required validation for file and affiliate id
      if (
        !!this.formData.file == false ||
        !!this.formData.affiliate_id == false
      ) {
        isValid = false;
      }

      if (!isValid) {
        //Show error message
        this.vrNotify.message = store.state.required.message;
        this.vrNotify.type = store.state.required.type;
        this.vrNotify.visible = store.state.required.visible;
        this.loading = false;
        return false;
      }

      //Make data to popst  as Form-Data
      var formDataUpload = new FormData();
      formDataUpload.append("affiliate_id", this.formData.affiliate_id); //"affl-001"
      formDataUpload.append("file", this.formData.file);

      adminAPI
        .importPatientDetails(formDataUpload)
        .then((response) => {
          if (response != null && response.data != null) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;
            this.formData = {};

            if (response.data.status) {
              if (response.data.results.total_error_records > 0) {
                this.allowDownload = true;
                this.batch_id = response.data.results.batch_id;
              }
            } else {
              if (response.data.results.total_error_records > 0) {
                this.allowDownload = true;
                this.batch_id = response.data.results.batch_id;
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    downloadErrorReport() {
      this.exportLoading = true;

      reportsAPI
        .downloadReport(
          "exportDocuments/errorReport",
          { batch_id: this.batch_id },
          "Error-Report.xlsx"
        )
        .then(() => {
          this.exportLoading = false;
        });
    },
  },

  computed: {
    vrNotify() {
      return store.state.notify;
    },
  },
  async beforeMount() {
    await Promise.all([
      // this.getBusinessModelOptionsData(),
      // this.getPriorityOptionsData(),
    ]);
  },
  created() {
    //Get permissions for logged in user
    this.permissions = permissions.getPermissions("search");
  },
};

//alert('AL');
