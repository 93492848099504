import apiClient from "./apiClient";

export default {
    importPatientDetails(data) {
    return apiClient.post("exportDocuments/importExcel", data);

    // return apiClient({
    //     method: "POST",
    //     url: 'http://localhost/actAPI/api/exportDocuments/importExcel',
    //     data: data,
    //   });
  },
};
