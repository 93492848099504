import store from "@/store/index";

export default {
  getPermissions(flag) {
    //------------------------------------------------------------
    //Conditions for Admin & User role
    //------------------------------------------------------------
    //--Admin Role--
    if (store.getters.user.role_id == 1) {
      store.state.allowAppointmentReport = true;
      store.state.allowUploadDetails = true;
      store.state.allowUserManagement = true;

      return {
        addPatient: true,
        allowAdd: true,
        allowEdit: true,
        allowDelete: true,
      };
    }
    //--Viewer Role--
    if (store.getters.user.role_id == 3) {
      store.state.allowAppointmentReport = true;
      store.state.allowUploadDetails = false;
      store.state.allowUserManagement = false;

      return {
        addPatient: true,
        allowAdd: false,
        allowEdit: false,
        allowDelete: false,
      };
    }
    //------------------------------------------------------------

    //------------------------------------------------------------
    //Conditions for user role
    //------------------------------------------------------------
    //--User Role--
    //if (flag == "search") {
    console.log(flag);
    if (store.getters.user.role_id == 2) {
      store.state.allowAppointmentReport = true;
      store.state.allowUploadDetails = true;
      store.state.allowUserManagement = false;

      return {
        addPatient: true,
        allowAdd: true,
        allowEdit: false,
        allowDelete: false,
      };
    }
    //------------------------------------------------------------
  },
};
